import { useContext } from 'react';
import { useAxios } from '../../utils/axios';
import axios from 'axios';
import { ThemeContext } from '../../contexts/ThemeContext';

// Helper function to generate a random color
const getRandomColor = () => {
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += '0123456789ABCDEF'[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Helper function to generate a unique identifier (scode)
const generateScode = () => {
  const currentdate = new Date();
  const datetime = `${currentdate.getFullYear()}${(currentdate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}${currentdate
    .getDate()
    .toString()
    .padStart(2, '0')}${currentdate
    .getHours()
    .toString()
    .padStart(2, '0')}${currentdate
    .getMinutes()
    .toString()
    .padStart(2, '0')}${currentdate
    .getSeconds()
    .toString()
    .padStart(2, '0')}`;
  return datetime + getRandomColor();
};

const useTracking = (pageType) => {
  const axiosInstance = useAxios();
  const theme = useContext(ThemeContext);

  const trackPage = async () => {
    // Ensure tracking only occurs if site status is 1
    if (theme.siteStatus === 1) {
      // Initialize IP details
      let ipDetails = {};

      try {
        // Attempt to fetch IP details using the primary service
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const ipDetailsResponse = await axios.get(`https://ipapi.co/${ipResponse.data.ip}/json/`);
        ipDetails = ipDetailsResponse.data;
      } catch (primaryError) {
        console.warn('Failed to fetch IP details from ipapi.co. Trying fallback...');

        try {
          // Attempt to fetch IP details using fallback service
          const fallbackResponse = await axios.get('https://ipwhois.app/json/');
          ipDetails = fallbackResponse.data;
        } catch (fallbackError) {
          console.warn('Failed to fetch IP details from fallback service. Proceeding without IP data.');
        }
      }



      // Prepare the payload
      const payload = {
       
        ipDetails: JSON.stringify(ipDetails), // Use empty object if all IP fetch attempts fail
        reference: window.location.hostname,
        track: 'track',
        page: window.location.href,
        method: 'open',
        page_type: pageType,
        UID: '', // Placeholder for UID generation
        scode: generateScode(), // Unique tracking identifier
      };

      // Send tracking data
      try {
        await axiosInstance.post('user/tracking', payload);
      } catch (error) {
        console.error('Error sending tracking data:', error);
      }
    } else {
      console.error('Tracking not enabled due to site status.');
    }
  };

  return trackPage;
};

export default useTracking;
