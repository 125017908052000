"use client";
import React, { useEffect } from 'react'

const NewRelicScript = () => {
    console.log("new relic script")
    useEffect(() => {
      if (typeof window !== 'undefined') {
        if (!document.querySelector('#nr-browser-agent')) {
          const script = document.createElement('script')
          script.src = '/js/newrelic.js' // Path to your New Relic script file
          script.id = 'nr-browser-agent'
          script.async = true
          script.onload = () => console.log('New Relic script loaded.')
          script.onerror = e => console.error('Failed to load New Relic script.', e)
          document.head.appendChild(script)
        }
      }
    }, [])
  
    return null
  }
  
  export default NewRelicScript