import React, { useEffect } from "react";
import { useJobId } from '../../contexts/ContextJbo';

const SnowfallEffect = () => {
  const { jboId } = useJobId();

  useEffect(() => {
    const endDate = new Date("2025-01-01");

    if (new Date() > endDate || jboId !== 233) {
      // Stop the snow effect if the date exceeds or jboId is not 233
      return;
    }

    const snowflakeContainer = document.querySelector(".snowflake-container");

    const createSnowflakes = () => {
      for (let i = 0; i < 100; i++) {
        const snowflake = document.createElement("div");
        snowflake.className = "snowflake";

        // Set random position and animation properties for natural movement
        snowflake.style.left = `${Math.random() * 100}vw`;
        snowflake.style.animationDuration = `${Math.random() * 5 + 6}s`; // Slow falling speed
        snowflake.style.animationDelay = `${Math.random() * 3}s`; // Staggered start
        snowflake.style.opacity = Math.random() * 0.8 + 0.2; // Slightly transparent snowflakes
        snowflake.style.fontSize = `${Math.random() * 16 + 10}px`; // Snowflake size
        snowflake.innerText = "❄";

        snowflakeContainer.appendChild(snowflake);

        // Remove snowflake after animation ends
        snowflake.addEventListener("animationend", () => {
          snowflake.remove();
        });
      }
    };

    createSnowflakes();
  }, []);

  return (
    <div>
  
      {jboId == 233 &&<>
       
      <div className="snowflake-container"></div>
      </> }
      
    </div>
  );
};

export default SnowfallEffect;
